export const donations = [
  "Matthew K.",
  "Joseph H.",
  "Wassimulator",
  "Jonas M.",
  "Martin N.",
  "Florian W.",
  "Abhishek V.",
  "Johanna Q.",
  "Bella N.",
  "Dennis S.",
  "Turgay Y.",
];
